import React, { useState,useEffect } from 'react'
import responseMessageServices from '../responseMessageServices';
import AppSelect from '../../../../components/AppInput/AppSelect';
import { useAlert } from '../../../../context/AlertContext';
import { useNavigate } from 'react-router-dom';
import { Delete, List, RadioButtonUnchecked } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, LinearProgress } from '@mui/material';

function InteractiveListMessageForm({data}) {
	const {AppAlert} = useAlert();
	const [dialogStatus, setDialogStatus] = useState(false);

	const [title,setTitle] = useState("");

	const [headerText,setHeaderText] = useState("");
	const [bodyText,setBodyText] = useState("");
	const [footerText,setFooterText] = useState("");
	const [menuButtonTitle,setMenuButtonTitle] = useState("");
	
	const [optionListTitle,setOptionListTitle] = useState("");
	const [optionTitle,setOptionTitle] = useState("");
	const [optionDescription,setOptionDescription] = useState("");
	const [selectedResponse,setSelectedResponse] = useState(null);

	const [optionList,setOptionList]	= useState([]);
	

	const [responseDDList,setResponseDDList] = useState();
	const [loading,setLoadingStatus] = useState(false)


	const autoFillData = ()=>{
		if(data){
			setTitle(data.title)
			setHeaderText(data.formValues.headerText);
			setBodyText(data.formValues.bodyText);
			setFooterText(data.formValues.footerText);
			setMenuButtonTitle(data.formValues.menuButtonTitle);
			setOptionListTitle(data.formValues.optionListTitle);

			setOptionList(data.formValues.optionList);

		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])

	const getResponseDDList = () =>{
		setLoadingStatus(true)
		responseMessageServices.getResponseDDList().then(res=>{
			

			if(data) {
				let _ddList  = res.data.filter(dd => dd.value != data.id);
				setResponseDDList(_ddList)
			} else {
				setResponseDDList(res.data);
			}

		}).catch(error=>{
		}).finally(()=>{
			setLoadingStatus(false)
		})
	}
	useEffect(()=>{
		getResponseDDList();
	},[])

	const addOptiontoList = () =>{
		let _optionList = [...optionList];
		let item = {
			"optionTitle" : optionTitle,
			"optionDescription" : optionDescription,
			"selectedResponse" : selectedResponse, 
		}
		
		setOptionList([..._optionList,item]);
		setOptionTitle("");
		setOptionDescription("");
		setSelectedResponse(null);
	}

	const removeOption = (i) =>{
		let _optionList = [...optionList];
        _optionList.splice(i,1)
        setOptionList(_optionList)
	}

	const prepareReplyResponse = () =>{
		let _responseMessage = {
			"type": "list",
			"body": {
				"text":bodyText
			},
		}
		if(headerText!==""){
			_responseMessage.header ={
				"type":"text",
				"text": headerText
			}
		}
		if(footerText!==""){
			_responseMessage.footer ={
				"text": footerText
			}
		}
		let _optionList = [];
		optionList.forEach(option => {
			let _option = {
				"id" :option.selectedResponse.value,
				"title": option.optionTitle,
			}
			if(option.optionDescription !==""){
				_option.description = option.optionDescription
			}
			  //-----------Make Dyanamic
			_optionList.push(_option);
		});
		let _action = {
			"button" : menuButtonTitle,  //-----------Make Dyanamic
			"sections":[
				{
					"title": optionListTitle, //-----------Make Dyanamic
					"rows":_optionList
				}
			]
		}
		_responseMessage.action = _action;
		return _responseMessage;
	}

	const prepareWhatsAppResponse = () =>{
		let _whatsAppResponse = {
			"messaging_product": "whatsapp",
    		"recipient_type": "individual",
			"type": "interactive",
			"interactive":prepareReplyResponse()
		}
		return JSON.stringify(_whatsAppResponse);
	}

	const prepareFormValues = () =>{
		let _formValues = {
			"headerText" :headerText,
			"bodyText":bodyText,
			"footerText":footerText,
			"optionList" : optionList,
			"menuButtonTitle":menuButtonTitle,
			"optionListTitle":optionListTitle,
		}
		return JSON.stringify(_formValues);
	}

	const navigate = useNavigate();
	const [saving,setSavingStatus] = useState(false)
	const onSubmit = () =>{
		let payload = {
			"title":title,
			"messageType":"interactiveList",
			"whatsAppResponse": prepareWhatsAppResponse(),
			"formValues":prepareFormValues()
		}
		console.log(payload);
		if(data){
			payload.id = data.id;
		}
		setSavingStatus(true);
		responseMessageServices.save(payload).then(res=>{
			AppAlert(res.msg)
			if(data){
				navigate("/admin/response-message/form/"+data.id)
			} else{
				navigate("/admin/response-message/form/"+res.id)
			}
			
		}).catch(error=>{
			AppAlert(error.msg,"error");
		}).finally(()=>{
			setSavingStatus(false);
		})
	}

	return (
    	<div className='row'>
			<div className='col-md-9'>
				<div className='card'>
					<div className='card-header'>Intractive List Message</div>
					<div className='card-body'>
						<div className='row'>
							<div className='col-md-6'>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={title}  onChange={(e)=>setTitle(e.target.value)}/>
									<label>Message Title (For Admin / Manager Use) *</label>
								</div>
							</div>
						</div>

						<hr/>

						<div className='row'>
							<div className='col-md-12'>
								<div className="form-floating">
									<input type="text" className="form-control" placeholder="" 
										value={headerText}  onChange={(e)=>setHeaderText(e.target.value)}
									/>
									<label>Header Text</label>
								</div>
							</div>
							<div className='col-md-12'>
								<div className="form-floating">
									<textarea className="form-control" placeholder="" style={{height:"100%"}} 
										value={bodyText}  onChange={(e)=>setBodyText(e.target.value)}
									></textarea>
									<label>Message Body *</label>
								</div>
							</div>
							<div className='col-md-12'>
								<div className="form-floating">
									<input type="text" className="form-control" placeholder="" 
										value={footerText}  onChange={(e)=>setFooterText(e.target.value)}
									/>
									<label>Footer Text</label>
								</div>
							</div>
						</div>
						<hr/>
						<h5>Available Options</h5>
						{responseDDList && responseDDList.length > 0 && 
							<div className='row'>
								<div className='col-md-6'>
									<div className="form-floating">
										<input type="text" className="form-control" placeholder="" 
											value={menuButtonTitle}  onChange={(e)=>setMenuButtonTitle(e.target.value)}
										/>
										<label>Menu Button Caption *</label>
									</div>
								</div>

								<div className='col-md-6'>
									<div className="form-floating">
										<input type="text" className="form-control" placeholder="" 
											value={optionListTitle}  onChange={(e)=>setOptionListTitle(e.target.value)}
										/>
										<label>Option List Title * </label>
									</div>
								</div>

								<div className='col-md-12 my-3'></div>
								<div className='col-md-3'>
									<div className="form-floating">
										<input type="text" className="form-control" placeholder="" 
											value={optionTitle}  onChange={(e)=>setOptionTitle(e.target.value)}
										/>
										<label>Option Title *</label>
									</div>
								</div>
								<div className='col-md-3'>
									<div className="form-floating">
										<input type="text" className="form-control" placeholder="" 
											value={optionDescription}  onChange={(e)=>setOptionDescription(e.target.value)}
										/>
										<label>Option Description (optional)</label>
									</div>
								</div>
								<div className='col-md-4'>
									<AppSelect
										options={[{"label":"--Select Response--",value:null},...responseDDList]}
										value={selectedResponse}
										setValue={setSelectedResponse}
										label="Select Response *" 
										optionLabel = "label"
									/>
								</div>
								{
								<div className='col-md-2'>
									<button 
										disabled={selectedResponse === null || selectedResponse?.value == null || optionTitle === "" } 
										className='btn btn-primary btn-lg' onClick={addOptiontoList}
									>
										Add
									</button>
								</div>
								}
							</div>
						}
						{responseDDList && optionList?.length > 0 &&
							<div className='row mt-2'>
								<div className='col-md-12'>
									<div className='card'>
										<div className='card-body bg-light'>
											<table className='table'>
												<thead>
													<tr>
														<th>Button Title</th>
														<th>Selected Response</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{optionList.map((option,i)=>
														<tr key={i}>
															<td>{option.optionTitle}</td>
															<td>{option.selectedResponse.label}</td>
															<td><button className='btn btn-danger btn-sm' onClick={()=>removeOption(i)}><Delete/> </button></td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
					<div className='card-footer'>
						{saving ? 
							<LinearProgress/>
							:
							<button 
								disabled={title=="" || bodyText == "" || optionList.length == 0 || menuButtonTitle == "" || optionListTitle == "" }
								className='btn btn-primary btn-lg float-end'
								type='button'
								onClick={onSubmit}
							>
								Save
							</button>
						}
					</div>
				</div>
			</div>
			<div className='col-md-3'>
				<h3 className='mx-3'>Preview</h3>
				<div className='bg-light border border-dark rounded p-2 mx-3 ps-5' style={{height:"70vh"}}>
						<div className='card'>
							<div className='card-body'>
								<h5>{headerText}</h5>
								<h6>{bodyText}</h6>
								<h6 className='text-muted'>{footerText}</h6>
							</div>
							<div className='card-footer text-center'>
								<button className='btn btn-outline-primary' onClick={()=>setDialogStatus(true)} ><List/> {menuButtonTitle}</button>
							</div>
						</div>
						
						<Dialog
                			open={dialogStatus}
                			keepMounted
                			onClose={()=>setDialogStatus(false)}
                			maxWidth="sm"
							fullWidth={true}
        				>
            				<DialogTitle>{optionListTitle}</DialogTitle>
							<DialogContent>
								<ul class="list-group">
								{optionList && optionList.map(option=>
									<li class="list-group-item d-flex justify-content-between align-items-center">
    									<span>
											<div className='fs-5'>{option.optionTitle}</div>
											{option.optionDescription !== "" && <div>{option.optionDescription}</div> }
										</span>
    									<span><RadioButtonUnchecked/></span>
  									</li>
								)}
								</ul>
							</DialogContent>
						</Dialog>
						
						
						
				</div>
			</div>
		</div>
	)
}

export default InteractiveListMessageForm